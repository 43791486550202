import { Component, OnInit } from '@angular/core';
import { IEmployee } from 'ng2-org-chart/src/employee';
import { PositionService ,DepartmentService, CompanyService, CommonService, CalendarHolidayService, EmployeeService} from 'src/app/shared';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-checkPass',
  templateUrl: './checkPass.component.html',
  styleUrls: ['./checkPass.component.css']
})
export class CheckPassComponent implements OnInit { 

  idNo: string = '';
  hasData: boolean = false;
  data: any = {};

  constructor(
    private employeeService: EmployeeService,
    private fb: FormBuilder
    ) {  
  }

  ngOnInit() {
      
  } 

  checkPass(){
    console.log(this.idNo);
    this.hasData = false;
    if(this.idNo){
      this.employeeService.getEmployeeByIdNo(this.idNo).subscribe(res=>{
        console.log(res);
        if(res && res.length > 0){
          this.hasData = true;
          this.data = res[0];
        }else{
          this.data = null;
        }
      })
    }
  }

  getDateStr(date){
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    return dd + '-' + mm + '-' + yyyy;
  }

  loadDefaultScript(){
    $('#calendar_holiday_table').DataTable();
  }

  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
